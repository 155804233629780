import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        time: -1
    },
    mutations: {
        timeset(state, n) {
            state.time = n
        }
    },
    actions: {},
    modules: {},
    getters: {
        routeQueryParams: (state, allGetters, rootState) => rootState.route.query,
        routeParams: (state, allGetters, rootState) => rootState.route.params,
        getSetTime: function (state, allGetters, rootState) {
            // console.log(rootState.route.params.time)
            if (state.time < 0) {
                //   console.log('time z 0')
                self.store.commit('timeset', rootState.route.params.time)
            }
            //   console.log(state)
            return state.time
        }
    }
})


