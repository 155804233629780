import Vue from 'vue'
import VueRouter from 'vue-router'
import countdownPage from "../pages/countdownPage";

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        name: 'CountdownBasic',
        component: countdownPage,
        props: true
    },
    {
        path: '/:time',
        name: 'Countdown',
        component: countdownPage,
        props: true
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
