import Vue from 'vue'
import App from './App.vue'
//import { sync } from 'vuex-router-sync'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'vue-datetime/dist/vue-datetime.css'
import moment from 'moment'
import { sync } from "vuex-router-sync";

sync(store, router);

Vue.config.productionTip = false
Vue.prototype.moment = moment
//const unsync = sync(store, router)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
