<template>
    <div id="app">
        <header>
            <Menu></Menu>
        </header>

        <main>
            <router-view></router-view>
        </main>
    </div>
</template>


<script>
    import moment from 'moment'
    import Menu from '@/components/Menu'
    export default {
        name: 'App',
        created () {
            if (this.$store.state.time === undefined || this.$store.state.time === -1) {
                if (this.$route.params.time === undefined) {
                    this.$router.push({name: 'Countdown', params: {time: moment().add(7, 'days').toISOString()}})
                }
            }
            if (this.$route.params.time !== undefined) {
                this.$store.commit('timeset', this.$route.params.time)
            }
        },
        components: {Menu}
    }


</script>

<style lang="scss">
    html,body {
        margin: 0;
    }
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }
    main {
        text-align: center;
        margin-top: 40px;
    }
</style>
