<template>
    <div>
        <div></div>

        <div class="sel">
            <label for="inputtime2">Counting down for</label>
            <datetime readonly="readonly"
                      input-class="datetimeinput"
                      type="datetime"
                      :format="{ year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit', timeZoneName: 'short' }"
                      :phrases="{ok: 'Continue', cancel: 'Exit'}"
                      :hour-step="2"
                      :minute-step="15"
                      :week-start="1"
                      use24-hour
                      auto
                      v-model="timen"
                      v-bind:value="timen"
                      :min-datetime="mintime"
                      id="inputtime2"
            ></datetime>
        </div>
        <div class="cd">
            <Countdown v-bind:end="timen" ></Countdown>
        </div>
    </div>
</template>

<script>
    import Countdown from '@/components/Countdown'
    import {Datetime} from 'vue-datetime'
    import Moment from 'moment'

    export default {
        name: "countdownPage"
        ,
        components: {Countdown, datetime: Datetime},
        created () {
            // console.log(this.$children)
        },
        computed: {
            timen: {
                get: function () {
                    return this.$store.getters.getSetTime
                },
                set: function (newValue) {
                    if (newValue !== undefined && newValue !== '') {
                        //   console.log('argh')
                        //  console.log(newValue)
                        this.$router.push({name: 'Countdown', params: { time: newValue }})
                        this.$store.commit('timeset', newValue)
                        //  console.log('hge2')
                    }
                }
            },
            mintime: function () {
                return Moment().add(1, 'hour').toISOString()
            }
        },
        watch: {
          //  'timen': function (newVal, oldVal) {
                // console.log('value changed from ' + oldVal + ' to ' + newVal)
          //  }
        }
    }

</script>

<style scoped>
    input:not([type=submit]):not([type=file]) {
        outline: none;
        border : none;
        border-bottom: 1px solid black;
    }
</style>
